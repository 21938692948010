@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0);
}

::-webkit-scrollbar-thumb{
  /* background: linear-gradient(to bottom, rgba(120,119,199), rgba(0,0,0)); Color of the thumb */
  background: rgb(90, 89, 148); /* Color of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering */
}